
import {
  defineComponent,
  reactive,
  ref,
  watch,
  onMounted,
  unref,
  ComponentOptionsBase,
  ComponentPublicInstance,
  Ref,
  computed,
} from "vue";
import { emailcode, register, loginApi } from "@/api/login";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import tools from "@/utils/tools";
import { ElMessage, LoadingParentElement, ElLoading } from "element-plus";
import type { FormInstance } from "element-plus";
import languageChange from "@/components/whatsapp/languageChange.vue";
import { useI18n } from "vue-i18n";
import { progressbarWs } from "@/api/login";
import Cookies from 'js-cookie'
import { config } from "@/utils/config";

export default defineComponent({
  name: "signup",
  components: {
    languageChange,
  },
  setup(prop, context) {
    let router = useRouter();
    const store = useStore();
    const i18n = useI18n();
    const ruleFormss = ref();
    const senMsg = 1;

    const formData = reactive({
      UserEmail: "",
      UserPassword: "",
      EmailCode: "",

      isShowPassword: false,
      isOk: false,
      EmailCodeButtonContent: "",
      // EmailCodeButtonContent: `$t('alreadyHaveAccount')`,
      EmailCodeButtonTime: 60,
      isSend: true,
      isAgree: true, // 保密协议是否勾选
      EmailTempToken: "",

      inputEmail: false,
      inputPassword: false,
      inputEmailCode: false,
      // btnText: "Sign up",
      isSignUp: true,
      disable: false,
      disableCode: true,
      isSendLoading: false,
    });

    watch(
      () => [formData.UserEmail, formData.UserPassword, formData.EmailCode],
      (newValue, oldVaule) => {
        if (formData.UserEmail && formData.UserPassword && formData.EmailCode) {
          formData.isOk = true;
        } else {
          formData.isOk = false;
        }
      }
    );

    let homePage = () => {
      if (window.location.origin.includes('parental')) {
        window.open("https://www.parentalguard.com/", "_blank");
      } else {
        window.open("https://www.famikeep.com/", "_blank");
      }
    };

    const emailRule = computed(() => {
      const rule = reactive({
        UserEmail: [
          { validator: validateUserNameASCII, trigger: "change" },
          {
            required: true,
            message: i18n.t("pleaseEnterEmailAddress"),
            trigger: "blur",
          },
          {
            required: true,
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: i18n.t("invalidEmailFormat"),
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^\S{1,100}$/,
            message: i18n.t("invalidEmailFormat"),
            trigger: "blur",
          },
        ],
        UserPassword: [
          // 验证邮箱输入正确
          // { validator: UserPasswordEasy, trigger: "blur" },

          { validator: validateUserPasswordASCII, trigger: "blur" },
          {
            required: true,
            message: i18n.t("pleaseEnterPassword"),
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#_~?&^]{8,20}$/,
            message: i18n.t("passwordMmustBe8"),
            trigger: "blur",
          },
        ],
        EmailCode: [
          // { required: true, message: "Code cannot be empty", trigger: "blur" },  // 请输入验证码
          {
            required: true,
            message: i18n.t("pleaseEnterVerifiCode"),
            trigger: "change",
          },

          { validator: validateUserEmailCodeASCII, trigger: "change" },

          {
            required: true,
            pattern: /^\d{6}$/,
            message: i18n.t("incorrectVerifiCode"),
            trigger: "blur",
          },
        ],
      });
      return rule;
    });

    const validateUserNameASCII = (
      _rule: any,
      value: string,
      callback: () => void
    ) => {
      var re = /[^a-zA-Z0-9!"#$%&'()*+,.\\\/:;<=>?@\[\] ^_`{|}~-]/g;
      formData.UserEmail = value.replace(re, "");
      callback();
    };
    const validateUserPasswordASCII = (
      _rule: any,
      value: string,
      callback: () => void
    ) => {
      var re = /[^a-zA-Z0-9!"#$%&'()*+,.\\\/:;<=>?@\[\] ^_`{|}~-]/g;
      formData.UserPassword = value.replace(re, "");
      callback();
    };
    const validateUserEmailCodeASCII = (
      _rule: any,
      value: string,
      callback: () => void
    ) => {
      var re = /[^0-9!"#$%&'()*+,.\\\/:;<=>?@\[\] ^_`{|}~-]/g;
      formData.EmailCode = value.replace(re, "");
      callback();
    };
    const UserPasswordEasy = (
      _rule: any,
      value: string,
      callback: () => void
    ) => {
      if (value) {
        var emailFirst = formData.UserEmail.split("@")[0];
        var emailFirstReverse = emailFirst.split("").reverse().join("");
        var valueReverse = value.split("").reverse().join("");
        if (
          valueReverse == formData.UserEmail ||
          value == formData.UserEmail ||
          value == emailFirst ||
          value == emailFirstReverse
        ) {
          //   callback(new Error('Password cannot be username, or reversed username'))
          callback();
        } else {
          callback();
        }
      }
    };

    // 验证
    let UserEmailTrim = () => {
      formData.UserEmail = tools.LTrim(tools.RTrim(formData.UserEmail));
    };

    let UserPasswordTrim = () => {
      formData.UserPassword = tools.LTrim(tools.RTrim(formData.UserPassword));
    };

    let UserEmailCodeTrim = () => {
      formData.EmailCode = tools.LTrim(tools.RTrim(formData.EmailCode));
      const formRef: any = unref(registerFormRef);
      formRef.validateField("EmailCode", (valid: boolean) => {
        if (valid) {
          formData.inputEmailCode = true;
          return true;
        } else {
          formData.inputEmailCode = false;
          return false;
        }
      });
    };
    const registerFormRef = ref<FormInstance>();
    // const formRef: any = unref(registerFormRef);
    let checkEmail = () => {
      // unref 如果参数是一个 ref 则返回它的 value，否则返回参数本身。
      const formRef: any = unref(registerFormRef);
      formRef.validateField("UserEmail", (valid: boolean) => {
        if (valid) {
          formData.inputEmail = true;
          formData.disableCode = false;
          return true;
        } else {
          formData.inputEmail = false;
          formData.disableCode = true;
          return false;
        }
      });
    };

    let checkPassword = () => {
      const formRef: any = unref(registerFormRef);
      formRef.validateField("UserPassword", (valid: boolean) => {
        if (valid) {
          formData.inputPassword = true;
          return true;
        } else {
          formData.inputPassword = false;
          return false;
        }
      });
    };

    let checkEmailCode = () => {
      // const formRef: any = unref(registerFormRef);
      // formRef.validateField("EmailCode", (valid: boolean) => {
      //   if (valid) {
      //     formData.inputEmailCode = true;
      //     return true;
      //   } else {
      //     formData.inputEmailCode = false;
      //     return false;
      //   }
      // });
    };

    // 验证码
    let setEmailcode = () => {
      const formRef: any = unref(registerFormRef);
      formRef.validateField("UserEmail", (valid: boolean) => {
        if (!valid) {
          return false;
        } else {
          if (!formData.isSend) return;
          formData.disableCode = true;
          formData.isSendLoading = true;
          let data = {
            type: 0,
            email: formData.UserEmail,
          };
          emailcode(data)
            .then((res) => {
              if (res.data.code == 200) {
                // 关于倒计时的控制
                let clock = window.setInterval(() => {
                  if (formData.EmailCodeButtonTime < 1) {
                    formData.EmailCodeButtonContent = "";
                    formData.EmailCodeButtonTime = 60;
                    formData.isSend = true;
                    clearInterval(clock);
                  } else {
                    formData.EmailCodeButtonContent = `${formData.EmailCodeButtonTime--}`;
                    formData.isSend = false;
                    formData.disableCode = false;
                    formData.isSendLoading = false;
                  }
                }, 1000);
                formData.EmailTempToken = res.data.data.token;
                ElMessage.success(i18n.t("verificationCodeHasBeen"));
              } else {
                tools.responseUse(res.data.code);
                // ElMessage.error(res.data.message);
                formData.disableCode = false;
                formData.isSendLoading = false;
              }
            })
            .catch((err) => {
              formData.disableCode = false;
              formData.isSendLoading = false;
            });
        }
      });
    };

    const refreshTab = () => {
      store.dispatch("getUserInfo");
      store.dispatch("getDeviceInfo");
      store.dispatch("updateMenuList");
    };

    let setRegister = () => {
      if (!formData.isOk) {
        return;
      }
      if (!formData.isAgree) {
        // duration 自动关闭时间
        ElMessage({
          message: i18n.t("pleaseReadAndAgree"),
          type: "error",
        });
        return;
      }
      let data = {
        email: formData.UserEmail,
        password: formData.UserPassword,
        token: formData.EmailTempToken,
        code: formData.EmailCode,
      };
      if (
        !(
          formData.inputEmail &&
          formData.inputPassword &&
          formData.inputEmailCode
        )
      ) {
        return;
      } else if (
        formData.inputEmail &&
        formData.inputPassword &&
        formData.inputEmailCode &&
        !data.token
      ) {
        ElMessage.error("Please get the verification code first.");
        return;
      }
      if (formData.disable === false) {
        formData.disable = true;
        // formData.btnText = "Creating  Account…";
        // 点击注册按钮后统计一次
        tools.UserEvent("Register_Button", "None");

        formData.isSignUp = false;
        register(data)
          .then((res) => {
            // console.log("注册成功", res);
            if (res.data.code == 200) {
              sessionStorage.setItem("resetTempEmail", formData.UserEmail);
              sessionStorage.setItem(
                "resetTempPassword",
                formData.UserPassword
              );
              tools.UserEvent("Register_Success", "None");

              let params = {
                email: formData.UserEmail,
                password: formData.UserPassword,
              };
              loginApi(params)
                .then((res) => {
                  if (res.data.code == "200") {
                    // router.push({ path: "/" });
                    localStorage.setItem("token", res.data.data.token);
                    // 登录密钥
                    localStorage.setItem("authorization", res.data.data.token);
                    console.log("res.data.authorization:", res.data.data.token);
                    Cookies.set('isLogin', 'true', { expires: 30 });

                    // Cookies.set('authorization', res.data.data.token, { expires: 30 });
                    store.commit('SET_Authorization', res.data.data.token);
                    localStorage.setItem(
                      "userInfo",
                      JSON.stringify(res.data.data)
                    );
                    localStorage.setItem("user_id", res.data.data.user_id);

                    tools.UserEvent("Login_Success", "None");
                    // start
                    store.dispatch("getDeviceInfo").then(async () => {
                      if (store.state.deviceInfo.length > 0) {
                        if (store.state.deviceInfo[0].id) {
                          store.commit(
                            "setCurrentDeviceId",
                            store.state.deviceInfo[0].id
                          );
                          store.commit("setCurrentDeviceInfo", store.state.deviceInfo[0]);
                          store.commit('setDeviceType', 0);
                        } else {
                          store.commit('setCurrentAppleId', store.state.deviceInfo[0].apple_id)
                          store.commit(
                            "setCurrentDeviceId",
                            store.state.deviceInfo[0].devices[0].id
                          );
                          store.commit("setCurrentDeviceInfo", { ...store.state.deviceInfo[0].devices[0], is_expired: store.state.deviceInfo[0].is_expired, expire_at: store.state.deviceInfo[0].expired_at });
                          store.commit('setDeviceType', 1);
                        }
                      }

                      await store.dispatch("getUserInfo");

                      if (!store.state.subscripts.expires_at) {
                        store.commit("setDefaultMenu", null);
                        router.push({ path: "/addDevice" });
                      } else if (
                        !store.state.deviceInfo.length &&
                        store.state.subscripts.expires_at
                      ) {
                        store.commit("setDefaultMenu", null);
                        router.push({ path: "/guide" });
                        store.commit("setCurrentDeviceId", "Device 1");
                        store.commit("setCurrentDeviceInfo", {
                          name: "leftTab.unboundName",
                          id: "Device 1",
                          model: "leftTab.unbound",
                        });
                        store.commit("setBoundImg", true);
                      } else {
                        router.push({ path: "/home" });
                        store.commit("setDefaultMenu", "home");
                      }

                      // // 调取设备信息
                      store.dispatch("updateMenuList");
                    });

                    ElMessage.success(i18n.t("loginSucceeded"));

                    let code = localStorage.getItem("authorization");
                    if (localStorage.getItem("authorization")) {
                      progressbarWs(code, 1);
                    }
                    if (localStorage.getItem("oos") == "1") {
                      refreshTab();
                      localStorage.setItem("oos", "3");
                    } else if (localStorage.getItem("oos") == "2") {
                      refreshTab();
                      localStorage.setItem("oos", "3");
                    }
                  } else {
                    tools.UserEvent("Login_Fail", res.data.code);
                    tools.responseUse(res.data.code);
                  }
                })
                .catch((err) => {
                  console.log("登录失败", err);
                  tools.UserEvent("Login_Fail", "None");
                  formData.disable = false;
                  // formData.btnText = "Sign up";
                  formData.isSignUp = true;
                });
            } else {
              tools.UserEvent("Register_Fail", res.data.code);
              tools.responseUse(res.data.code);
              // ElMessage.error(res.data.message);
              formData.disable = false;
              formData.isSignUp = true;
            }
          })
          .catch((err) => {
            tools.UserEvent("Register_Fail", "None");
            console.log("注册失败", err);
            formData.disable = false;
            formData.isSignUp = true;
          });
      }
    };

    let goLogIn = () => {
      router.push({ path: "/login" });
    };

    // V=1.0.0 版本号跟着项目版本号走
    let goTermsOfService = () => {
      if (window.location.origin.includes('parental')) {
        window.open(
          `https://cbs.parentalguard.com/go?pid=7722&a=tc&v=${config.versionValue}`,
          "_blank"
        );
      } else {
        window.open(
          `https://cbs.famikeep.com/go?pid=5530&a=tc&v=${config.versionValue}`,
          "_blank"
        );
      }
    };

    // V=1.0.0 版本号跟着项目版本号走
    let goPrivacyPolicy = () => {
      if (window.location.origin.includes('parental')) {
        window.open(
          `https://cbs.parentalguard.com/go?pid=7722&a=p&v=${config.versionValue}`,
          "_blank"
        );
      } else {
        window.open(
          `https://cbs.famikeep.com/go?pid=5530&a=p&v=${config.versionValue}`,
          "_blank"
        );
      }
    };

    let activeLink = () => {
      formData.isAgree = !formData.isAgree;
    };

    return {
      store,
      emailRule,
      registerFormRef,
      formData,
      UserEmailTrim,
      UserPasswordTrim,
      UserEmailCodeTrim,
      setEmailcode,
      setRegister,
      goLogIn,
      goTermsOfService,
      goPrivacyPolicy,
      checkEmail,
      checkPassword,
      checkEmailCode,
      senMsg,
      activeLink,
      homePage,
    };
  },
});
